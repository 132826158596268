import React from 'react';
import PropTypes from 'prop-types';
import './HeroGrid.css';

const HeroGrid = (
    { 
        title , 
        subtitle, 
        description, 
        firstBackgroundImage, 
        firstBackgroundAlt, 
        secondBackgroundImage
        ,secondBackgroundAlt
    }
) => {

    return (
        <div className='hero-grid-container'>
            <div className='hero-grid-content container'>
                <h1 className='hero-grid-title'>{title}</h1>
                <p className='hero-grid-subtitle'>{subtitle}</p>
                <p className='hero-grid-description'>{description}</p>
            </div>

            <div
                className="first-image-box"
            >
                <img className='hero-grid-img' src={firstBackgroundImage} alt={firstBackgroundAlt}/>
            </div>
            
            <div
                className="second-image-box"
            >
                <img className='hero-grid-img' src={secondBackgroundImage} alt={secondBackgroundAlt}/>
            </div>
        </div>
    );
};

HeroGrid.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    firstBackgroundImage: PropTypes.string.isRequired,
    firstBackgroundAlt: PropTypes.string,
    secondBackgroundImage: PropTypes.string.isRequired,
    secondBackgroundAlt: PropTypes.string,
};

HeroGrid.defaultProps = {
    firstBackgroundAlt: "",
    secondBackgroundAlt: "",
    title: "",
    subtitle: "",
    description: ""
};

export default HeroGrid;
