import React from 'react'
import HeroGrid from "../../../Constant/HeroGrid/HeroGrid";
import FirstImage from "../../../../Assets/images/WhatWeDo/CriminalCase/hero-image-01.png";
import SecondImage from "../../../../Assets/images/WhatWeDo/CriminalCase/hero-image-02.png";
import ContactForm from "../../../Constant/ContactForm/Contact Form";
import sectionImageOne from "../../../../Assets/images/WhatWeDo/CriminalCase/section-image-01.png";
import AlternatingSection from "../../../Constant/AlternatingSection/AlternatingSection";

const sectionsData = [
    {
        text:
        "With our experience and knowledge in the field of Criminal Law, we, at CABINET D'AVOCATS MOHAMED & C, defend our clients in the best way. On the other hand, we ensure that the evidence subject to the lawsuit is meticulously and carefully investigated and added to the defense in order to protect the freedom, material and moral personal rights of the clients and during the trial.",
        image: sectionImageOne,
        alt: "Image 1",
    }
];

function CriminalCases () {
    return (
        <main className='main'>
            <HeroGrid
                secondBackgroundImage={SecondImage}
                firstBackgroundImage={FirstImage}
                title='Criminal cases'
                description="In the investigations and prosecutions carried out regarding the crimes committed in Comoros, the basic laws that determine the rules and practices regarding the definition of the crime, the punishment to be applied to the crime, and the trial are Chapter 154 Penal Code and Chapter 155 Criminal Procedure Law."
            />

            <section className='page-banner-section'>
                <div className='page-banner-container container'>
                    <p className='page-banner-text dark-blue'>
                        As stipulated in Article 1 of the Comoros Constitution, democracy, social justice, and the rule
                        of law, for the peaceful coexistence of the social order, it is under the duty and obligation to
                        prevent crimes, to investigate them when they are committed, to give a fair trial to the
                        defendants, and to execute the sentences of those who have been found guilty and convicted. In
                        the fulfillment of these duties and obligations, it is obligatory to carry out practices that
                        require interference with personal rights and freedoms.
                    </p>
                </div>
            </section>

            <section className='page-banner-section dark-bg'>
                <div className='page-banner-container container'>
                    <h1 className='page-banner-text white medium-size'>
                        On the other hand, for an act to constitute a criminal offense to be punished, certain elements
                        must be present. The basic elements that make up the crime are the legal element, the material
                        element, the element of illegality, and the moral element.
                    </h1>
                    <p className='page-banner-text gray'>
                        According to Article 18(1) of the Comoros Constitution, <span className='white'>"No one shall be deemed guilty of an act
                        or omission that did not constitute a crime under the law when it was committed..."</span> This
                        requires that the definition of crime be clearly and unambiguously regulated by law. Crime
                        cannot be defined by vague and ambiguous expressions.
                    </p>
                    <div className='page-banner-border'></div>
                    <p className='page-banner-text white normal-size'>
                        Again, in order for the crime to occur, the perpetrator must commit an act that constitutes the
                        subject of the crime. What is meant by the verb is that a person creates a job that changes the
                        outside world with his own will. That is, there must be a causal link between the act and the
                        result.
                    </p>
                </div>
            </section>

            <section className='page-banner-section'>
                <div className='page-banner-container container'>
                    <p className='page-banner-text'>
                        According to Article 154 of Chapter 26 of the Penal Code, the types of punishments that the
                        courts can impose are imprisonment, fines, payment of compensation, the granting of bail with or
                        without a surety for the maintenance of peace and tranquility and good morals, or the deposit of
                        a cash bond and supervision.
                    </p>
                </div>
            </section>
            
            <AlternatingSection sections={sectionsData} />

            <ContactForm/>
        </main>
    )
}

export default CriminalCases;