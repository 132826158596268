import React from 'react'
import HeroImage from "../../../Assets/images/NotariesServices/hero-image.png";
import DownLoadIcon from "../../../Assets/images/NotariesServices/download-icon.png";
import ContactForm from "../../Constant/ContactForm/Contact Form";
import AlternatingContainer from "../../Constant/AlternatingContainer/AlternatingContainer";
import HeroSection from "../../Constant/HeroSection/HeroSection";
import Nawab_A from '../../../Assets/pdf/Nawab_A.pdf'
import Thilivhali_O from '../../../Assets/pdf/Nawab_A.pdf'
import Mohamed_B from '../../../Assets/pdf/Nawab_A.pdf'

const sectionsData = [
    {
        leftTitle: "(1) Real Estate Transactions",
        leftText:
            "Our notaries specialize in real estate transactions, ensuring that property transfers are conducted smoothly and in compliance with all legal requirements.",
        rightTitle: "(2) Estate Planning",
        rightText:
            "We provide notary services for estate planning, including the drafting of wills and powers of attorney. Our goal is to help clients secure the future of their assets and provide \n" +
            "for their loved ones.",
    },
    {
        leftTitle: "(3) Contract Notarization",
        leftText:
            "Cabinet d'Avocats Mohamed & C offers notary services for the notarization of various contracts, guaranteeing their legal validity and enforceability.",
        rightTitle: "(4) Legal Consultation",
        rightText:
            "Clients can consult our notaries for expert advice on legal matters. Whether it's understanding the implications of Portfolio.css document or seeking guidance on legal processes, our notaries are here \n" +
            "to assist."
    }
];

function NotariesServices () {
    return (
        <main className='main'>
            <HeroSection 
                heroBackground={HeroImage}
                subtitle='Our notary services are designed to provide our clients with peace of mind, knowing that their legal affairs are in capable hands.'
                buttonTitle='Contact Us'
                buttonLink='/contact'
            />
            
            <AlternatingContainer sections={sectionsData} sectionTitle='Notary Services We Offer:' />
            
            <section >
                <h1 className='section-title container'>
                    Our approved notaries:
                </h1>

                <div className='layout-row-container container'>
                    
                    <article className='layout-row-article '>
                        <div className=''>
                            <h2 className='layout-row-title'>
                                Nawab Ali
                            </h2>
                            <p className='layout-row-title'>
                                Master of International Business Law, University of Perpignan
                            </p>
                        </div>

                        <div className='layout-row-download'>
                            <span className='download-text'>
                                Download <br/>
                                representative letter
                            </span>
                            <div className='download-actions'>
                                <img src={DownLoadIcon} className='download-icon pointer' alt='download icon'/>
                                <a href={Nawab_A} target='_blank' className='download-link'>
                                    Nawab_A.pdf
                                </a>
                            </div>
                        </div>
                    </article>

                    <article className='layout-row-article '>
                        <div className=''>
                            <h2 className='layout-row-title'>
                                Thilivhali Okello
                            </h2>
                            <p className='layout-row-title'>
                                Business Tax Lawyer
                            </p>
                        </div>

                        <div className='layout-row-download'>
                            <span className='download-text'>
                                Download <br/>
                                representative letter
                            </span>
                            <div className='download-actions'>
                                <img src={DownLoadIcon} className='download-icon pointer' alt='download icon'/>
                                <a href={Thilivhali_O} target='_blank' className='download-link'>
                                    Thilivhali_O.pdf
                                </a>
                            </div>
                        </div>
                    </article>

                    <article className='layout-row-article '>
                        <div className=''>
                            <h2 className='layout-row-title'>
                                Mohamed Boina
                            </h2>
                            <p className='layout-row-title'>
                                Attorney at Law - COMOROS BAR
                            </p>
                        </div>

                        <div className='layout-row-download'>
                            <span className='download-text'>
                                Download <br/>
                                representative letter
                            </span>
                            <div className='download-actions'>
                                <img src={DownLoadIcon} className='download-icon pointer' alt='download icon'/>
                                <a href={Mohamed_B} target='_blank' className='download-link'>
                                    Mohamed_B.pdf
                                </a>
                            </div>
                        </div>
                    </article>
                </div>
            </section>

            <ContactForm/>
        </main>
    )
}

export default NotariesServices;