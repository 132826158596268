import React from 'react';
import './Footer.css'
import {Link} from "react-router-dom";
import HeaderLogo from "../../Assets/images/Footer/footer-logo.png";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineEmail } from "react-icons/md";

function ContactUs () {
    return (
        <footer className='footer'>
            <div className='footer-container container'>

                <ul className="footer-list ">
                    <li className='footer-mobile-data'>
                        <Link to='/' className="footer-logo">
                            <img src={HeaderLogo} alt='header logo' className='header-logo-img'/>
                        </Link>
                    </li>

                    <li>
                        <Link to='/notaries-services' className='footer-link'>
                            Home Page
                        </Link>
                    </li>
                    <li>
                        <Link to='/notaries-services' className='footer-link'>
                            Notaries & Services
                        </Link>
                    </li>
                    <li className='footer-desktop-data'>
                        <Link to='/' className="footer-logo">
                            <img src={HeaderLogo} alt='header logo' className='header-logo-img'/>
                        </Link>
                    </li>
                    {[ // Main menu links
                        {label: 'The Notaries Society', path: '/notaries-society'},
                        {label: 'Our Partners', path: '/our-partners'},
                        {label: 'Contact Us', path: '/contact-us'}
                    ].map((link, index) => (
                        <li key={index}>
                            <Link to={link.path} className="footer-link">
                                {link.label}
                            </Link>
                        </li>
                    ))}
                </ul>

                <ul className="footer-sublist container">
                    {[ // Dropdown items with associated routes
                        {name: 'Divorce proceedings', route: '/divorce-proceedings'},
                        {name: 'Division of Property in Divorce', route: '/division-of-property'},
                        {
                            name: 'Real Estate and Construction Law in Comoros',
                            route: '/real-estate-comoros'
                        },
                        {name: 'Real Estate', route: '/real-estate'},
                        {name: 'Criminal cases', route: '/criminal-cases'},
                        {name: 'Notary services', route: '/notary-services'}
                    ].map((item, index) => (
                        <li key={index}>
                            <Link
                                to={item.route}
                                className="footer-sublink"
                            >
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>

                <div className='footer-contact-box'>
                    <div className='footer-contact'>
                        <MdOutlineEmail className='footer-contact-icon' />
                        <a href='mailto:support@cabadvocats.com' className='footer-contact-link'>
                            support@cabadvocats.com
                        </a>
                    </div>
                    <div className='footer-contact'>
                        <CiLocationOn className='footer-contact-icon'/>
                        <span className='footer-contact-link'>
                            Boulevard Karthala, Darsanda, Bâtiment Ridjali, 
                            1er étage, Fomboni, Mwali, Comores
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default ContactUs;