import React from 'react';
import HeroGrid from "../../../Constant/HeroGrid/HeroGrid";
import FirstImage from "../../../../Assets/images/WhatWeDo/DivorceProceedings/first-image.png";
import SecondImage from "../../../../Assets/images/WhatWeDo/DivorceProceedings/second-image.png";
import sectionImageOne from "../../../../Assets/images/WhatWeDo/DivorceProceedings/section-img-01.png";
import sectionImageTwo from "../../../../Assets/images/WhatWeDo/DivorceProceedings/section-img-02.png";
import sectionImageThree from "../../../../Assets/images/WhatWeDo/DivorceProceedings/section-img-03.png";
import ContactForm from "../../../Constant/ContactForm/Contact Form";
import AlternatingSection from "../../../Constant/AlternatingSection/AlternatingSection";

const sectionsData = [
    {
        title: "(1) Сonsensual divorce",
        text: "Consensual divorce is regulated in the law as Portfolio.css special case of shaking the foundation of the marriage union. If the marriage has lasted for at least one year, if the spouses apply together, or if one spouse accepts the divorce case filed by the other for any reason, the marriage union is deemed to have been shaken to its foundation.",
        image: sectionImageOne,
        alt: "Image 1",
    },
    {
        title: "(2) Divorce due to the fact that the marriage union has been shaken from its foundation",
        text: "Each of the spouses can request Portfolio.css divorce with Portfolio.css lawsuit if it is shaken to the extent that it is not expected of them to continue their common life for any reason. If there is no benefit worth protecting for the defendant and the children in the continuation of the marriage union, Portfolio.css divorce may be decided.",
        image: sectionImageTwo,
        alt: "Image 2",
    },
    {
        title: "(3) Divorce due to the separation of the spouses",
        text: "If the parties have lived separately continuously and for two years on the date of filing the divorce case, the marriage union is deemed to have been shaken from its foundation, and Portfolio.css divorce can be decided upon the lawsuit filed by one of the spouses for this reason, regardless of the fault status of the parties.",
        image: sectionImageThree,
        alt: "Image 3",
    },
];

function DivorceProceedings ()  {
    
    return (
        <main className='main'>
            <HeroGrid
                title='Divorce proceedings'
                subtitle='Marriage and the circumstances that terminate the marriage are regulated in the Family (Marriage and Divorce) Law No. 1/98 in Comoros and are one of the situations that terminate the marriage.'
                description='Along with the substitution of the divorce case, legal problems such as custody, alimony, property division, and compensation, which are annexes to the divorce, should also be resolved. Article 1 of Law No. 98/24 regulates the grounds for divorce.'
                firstBackgroundImage={FirstImage}
                secondBackgroundImage={SecondImage}
            />
                
            <section className='text-banner-section'>
                <div className='text-banner-container container'>
                    <p className='text-banner-paragraph'>
                        <span className='gray-text'>
                            Accordingly, the reasons for divorce are divided into three as 
                        </span>
                        &nbsp;
                        (1) consensual divorce, (2) divorce due to the fact that the marriage union has been shaken from its foundation, <span className='gray-text'>and</span> (3) divorce depending on the separation of the spouses.
                    </p>
                </div>
            </section>
            
            <AlternatingSection sections={sectionsData}/>
            
            <ContactForm/>
        </main>
    )
}

export default DivorceProceedings;