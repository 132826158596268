import {useState} from "react";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import ContactForm from "../../Constant/ContactForm/Contact Form";

function ThilivhaliOkello() {

    return (
        <main className='main'>
            <div className='portfolio-header'>
                <div className='portfolio-header-container container'>
                    <h1 className='portfolio-header-title'>
                        Thilivhali Okello
                    </h1>

                    <p className='portfolio-header-description'>
                        Business Profile: Thilivhali Okello
                        <br/> <br/>
                        Business Tax Lawyer
                    </p>
                </div>
            </div>

            <section className='portfolio-section section'>
                <div className='portfolio-container container'>
                    <div className='portfolio-content'>
                        <h1 className='portfolio-title'>
                            Overview:
                        </h1>
                        <p className='portfolio-description'>
                            Thilivhali Okello is a highly accomplished lawyer with a distinguished background in
                            business tax law. A graduate of the prestigious University of Comoros, Thilivhali has honed
                            his expertise through academic excellence and practical experience, making him a trusted
                            professional in the legal landscape of the Comoros Islands.
                        </p>

                        <h1 className='portfolio-title'>
                            Education:
                        </h1>
                        <p className='portfolio-description'>
                            Thilivhali Okello earned his degree in Business Tax Law from the University of Comoros. His
                            academic journey laid the foundation for his in-depth understanding of the complexities
                            surrounding business taxation, providing him with the knowledge required to navigate the
                            intricate legal landscape.
                        </p>
                        <h1 className='portfolio-title'>
                            Professional Experience:
                        </h1>
                        <p className='portfolio-description'>
                            Thilivhali boasts a decade of professional experience as a notary, having dedicated the last
                            ten years of his career to excellence in the legal field. He has been an integral part of
                            the esteemed CABINET D'AVOCATS MOHAMED & C law firm, contributing to its reputation as a
                            leading legal entity in the Comoros Islands.
                        </p>
                        <h1 className='portfolio-title'>
                            Expertise:
                        </h1>
                        <p className='portfolio-description'>
                            Thilivhali's expertise lies in business tax law, where he has consistently demonstrated a
                            keen understanding of the regulatory framework and a commitment to providing strategic legal
                            solutions for his clients. His proficiency extends to notarial services, offering a
                            comprehensive approach to legal matters that require precision and attention to detail.
                        </p>
                        <h1 className='portfolio-title'>
                            Professionalism in Comoros:
                        </h1>
                        <p className='portfolio-description'>
                            Thilivhali Okello is deeply committed to upholding the highest standards of professionalism
                            within the legal community of the Comoros Islands. His local knowledge, combined with global
                            perspectives gained through academic pursuits, positions him as a valuable asset for clients
                            seeking legal counsel in the region.
                        </p>
                        <h1 className='portfolio-title'>
                            Philosophy:
                        </h1>
                        <p className='portfolio-description'>
                            Thilivhali believes in a client-centric approach, prioritizing clear communication, ethical
                            practice, and a results-driven mindset. His commitment to providing tailored legal solutions
                            reflects in his track record of successful client outcomes.
                        </p>
                    </div>

                    <div className="table-container">
                        <table className="table">
                            <thead className="table-header">
                            <tr>
                                <th className="table-heading">№</th>
                                <th className="table-heading">document number</th>
                                <th className="table-heading">Date of Issue</th>
                                <th className="table-heading">document type</th>
                                <th className="table-heading">status</th>
                            </tr>
                            </thead>
                            <tbody className="table-body">
                            <tr className="table-row">
                                <td className="table-cell">1</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">2</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">2</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">3</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">4</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">5</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='portfolio-list-container'>
                        <h1 className='portfolio-list-title'>
                            How to verify the authenticity of a document:
                        </h1>
                        <ul className='portfolio-list'>
                            <li className='portfolio-list-item'>
                                1. Scan the QR code located on the document.
                            </li>
                            <li className='portfolio-list-item'>
                                2. Enter the apostille registration number in the verification field on the website
                                (link to our site).
                            </li>
                            <li className='portfolio-list-item'>
                                3. Ensure that the information in the registry matches what is listed in the table.
                            </li>
                        </ul>

                    </div>

                    <h1 className='section-title'>
                        Frequently Asked Questions
                    </h1>

                </div>
                <FAQ/>
                <ContactForm/>
            </section>
        </main>
    )
}

export default ThilivhaliOkello;


const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const faqs = [
        {
            question: "What should I do if a document is missing from the table?",
            answer: "Please contact our office to clarify the information by sending an email to support@cabadvocats.com. The document might still be in the process of registration."
        },
        {
            question: "How can I find out if my document is ready?",
            answer: "Check the document's status using its registration number or contact us using the provided details: support@cabadvocats.com."
        },
    ];

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="faq-container container">
            {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                    <button className="faq-question" onClick={() => toggleFAQ(index)}>
                        {faq.question}
                        <span className="faq-icon">{openIndex === index ?
                            <IoIosArrowDown/>
                            : <IoIosArrowUp/>
                        }</span>
                    </button>
                    {openIndex === index && <p className="faq-answer">{faq.answer}</p>}
                </div>
            ))}
        </div>
    );
};