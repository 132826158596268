import React from "react";
import FirstImage from "../../../../Assets/images/WhatWeDo/NotaryServices/hero-image-01.png";
import SecondImage from "../../../../Assets/images/WhatWeDo/NotaryServices/hero-image-02.png";
import HeroGrid from "../../../Constant/HeroGrid/HeroGrid";
import AlternatingSection from "../../../Constant/AlternatingSection/AlternatingSection";
import sectionImageOne from "../../../../Assets/images/WhatWeDo/NotaryServices/section-image-01.png";
import sectionImageTwo from "../../../../Assets/images/WhatWeDo/NotaryServices/section-image-02.png";
import sectionImageThree from "../../../../Assets/images/WhatWeDo/NotaryServices/section-image-03.png";
import ContactForm from "../../../Constant/ContactForm/Contact Form";

const sectionsData = [
    {
        title: "(1) Verification and Authentication",
        text: 
        "Our notaries are tasked with the responsibility of verifying the authenticity of documents. This includes scrutinizing the identity of the parties involved and ensuring that the information presented is accurate.",
        image: sectionImageOne,
        alt: "Image 1",
    },
    {
        title: "(2) Legal Advice",
        text:
        "Beyond authentication, our notaries offer valuable legal advice related to the documents they handle. Whether it's Portfolio.css real estate transaction, wills, contracts, or other legal instruments, our notaries provide guidance to ensure that our clients make informed decisions.",
        image: sectionImageTwo,
        alt: "Image 2",
    },
    {
        title: "(3) Drafting and Preparation",
        text:
        "Notaries at Cabinet d'Avocats Mohamed & C can assist in the drafting and preparation of various legal documents. This includes contracts, affidavits, and other agreements, ensuring that they comply with applicable laws and regulations.",
        image: sectionImageThree,
        alt: "Image 2",
    }
];

const NotaryServices = () => {
    return (
        <main className="main">
            <HeroGrid
                title='Notary Services'
                subtitle="At Cabinet d'Avocats Mohamed & C, we understand the critical role that notary services play in ensuring the legality and authenticity of various documents."
                firstBackgroundImage={FirstImage}
                secondBackgroundImage={SecondImage}
            />

            <section className='page-banner-section'>
                <div className='page-banner-container container'>
                    <p className='page-banner-text gray'>
                        Our dedicated team of notaries is committed to <span className='dark-blue'>
                        providing comprehensive services that adhere to
                        the highest standards
                    </span> of professionalism and legal expertise.
                    </p>
                </div>
            </section>
            
            <div className='section '>
                <h1 className='section-title container'>
                    Our services:
                </h1>

                <AlternatingSection sections={sectionsData}/>

            </div>
            

            <ContactForm />

        </main>
    );
};

export default NotaryServices;
