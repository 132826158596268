import React, {useState, useEffect, useCallback, useRef} from 'react';
import {RiMenuLine, RiCloseLine, RiArrowDownSLine, RiArrowUpSLine} from 'react-icons/ri';
import './Header.css';
import HeaderLogo from '../../Assets/images/Header/header-logo.svg';
import {Link} from 'react-router-dom';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [isToggleClicked, setIsToggleClicked] = useState(false);
    const headerRef = useRef(null);

    const toggleMenu = useCallback(() => {
        setIsToggleClicked(true);
        setMenuOpen((prevMenuOpen) => !prevMenuOpen);
    }, []);

    const toggleDropdown = useCallback((dropdownName) => {
        setOpenDropdown((prevDropdown) => (prevDropdown === dropdownName ? null : dropdownName));
    }, []);

    const scrollToTopAndCloseMenu = useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setMenuOpen(false);
        setOpenDropdown(null);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1118) {
                setMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (<header className="header" ref={headerRef}>
            <nav className="nav container">
                <div className="nav-data">
                    <Link to='/' className="nav-logo" onClick={scrollToTopAndCloseMenu}>
                        <img src={HeaderLogo} alt='header logo' className='header-logo-img'/>
                    </Link>

                    <div className="nav-toggle" onClick={toggleMenu}>
                        {menuOpen ?
                            <RiCloseLine className="nav-toggle-close"/>
                            :
                            <RiMenuLine className="nav-toggle-menu"/>
                        }
                    </div>
                </div>
                
                <div className={`nav-menu  ${menuOpen ? 'show-menu' : ''}`}>
                    <ul className="nav-list ">
                        <li className={`dropdown-item ${openDropdown === "whatWeDo" ? 'show-dropdown' : ''}`}>
                            <div
                                className="nav-link dropdown-button"
                                onClick={() => toggleDropdown('whatWeDo')}
                            >
                                <span>
                                    What We Do
                                </span>
                                {openDropdown === 'whatWeDo' ? (
                                    <RiArrowUpSLine className="dropdown-arrow"/>
                                ) : (
                                    <RiArrowDownSLine className="dropdown-arrow"/>
                                )}
                            </div>

                            <div className={`dropdown-container ${openDropdown === 'whatWeDo' ? 'dropdown-open' : ''}`}>
                                <ul className="dropdown-list container">
                                    {[
                                        // Dropdown items with associated routes
                                        {name: 'Divorce proceedings', route: '/divorce-proceedings'},
                                        {name: 'Division of Property in Divorce', route: '/division-of-property'},
                                        {
                                            name: 'Real Estate and Construction Law in Comoros',
                                            route: '/real-estate-comoros'
                                        },
                                        {name: 'Real Estate', route: '/real-estate'},
                                        {name: 'Criminal cases', route: '/criminal-cases'},
                                        {name: 'Notary services', route: '/notary-services'}
                                    ].map((item, index) => (
                                        <li key={index}>
                                            <Link
                                                to={item.route}
                                                className="dropdown-link"
                                                onClick={scrollToTopAndCloseMenu}
                                            >
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </li>

                        <li>
                            <Link to='/notaries-services' className='nav-link' onClick={scrollToTopAndCloseMenu}>
                                Notaries & Services
                            </Link>
                        </li>

                        <li className='nav-desktop-data'>
                            <Link to='/' className="nav-logo" onClick={scrollToTopAndCloseMenu}>
                                <img src={HeaderLogo} alt='header logo' className='header-logo-img'/>
                            </Link>
                        </li>

                        {[ // Main menu links
                            {label: 'The Notaries Society', path: '/notaries-society'},
                            { label: 'Our Partners', path: '/our-partners' },
                            { label: 'Contact Us', path: '/contact-us' }
                        ].map((link, index) => (
                            <li key={index}>
                                <Link to={link.path} className="nav-link" onClick={scrollToTopAndCloseMenu}>
                                    {link.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
