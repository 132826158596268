import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './HeroSection.css';

const HeroSection = ({ heroBackground, alt, title, subtitle, buttonTitle, buttonLink }) => {
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate(buttonLink);
    };

    return (
        <div
            className="hero-container"
            style={{
                backgroundImage: `url(${heroBackground})`,
            }}
        >
            <div className="hero-content container">
                <h1 className="hero-title">{title}</h1>
                <h2 className="hero-subtitle">{subtitle}</h2>
                {buttonTitle && (
                    <button className='hero-button' type="button" onClick={handleNavigation}>
                        {buttonTitle}
                    </button>
                )}
            </div>
        </div>
    );
};

HeroSection.propTypes = {
    heroBackground: PropTypes.string.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    alt: PropTypes.string,
    buttonTitle: PropTypes.string,
    buttonLink: PropTypes.string,
};

HeroSection.defaultProps = {
    alt: 'Banner page Image',
    title: '',
    buttonTitle: '',
    subtitle: '',
    buttonLink: '/',
};

export default HeroSection;
