import React from 'react';
import ContactForm from "../../Constant/ContactForm/Contact Form";
import HeroImage from '../../../Assets/images/ContactUs/ssection-image.png'
import SectionImageOne from "../../../Assets/images/MohamedBoina/section-image-01.png";
import HeroSection from "../../Constant/HeroSection/HeroSection";

function ContactUs () {
    return (
        <main className='main'>
            
            <HeroSection heroBackground={HeroImage} title="Contact Information" />
            
            <section className='layout-section section'>
                <div className='layout-container container'>
                    
                    <h1 className='section-title'>
                        CABINET D'AVOCATS MOHAMED & C
                    </h1>
                    <br/>
                    <br/>
                    
                    <div
                        className='alternating-layout-item'
                    >
                        {/* Left Content */}
                        <div className='alternating-layout-left'>
                            <h2 className='alternating-title'>About Us:</h2>
                            <p className='alternating-text'>
                                Founded by MOHAMED BOINA , a distinguished legal professional
                                with a solid academic background from the University of Comoros in the field of business
                                Law, our firm boasts a decade of expertise as a reputable notary. Committed to upholding
                                the
                                highest standards of legal practice, CABINET D'AVOCATS MOHAMED & C Law Law Firm is your
                                go-to destination for comprehensive legal solutions tailored to the unique needs of
                                businesses in the Comoros.
                            </p>
                        </div>

                        {/* Right Content */}
                        <div className='alternating-layout-right'>
                            <img src={SectionImageOne} alt='image' className='alternating-layout-img'/>
                        </div>
                    </div>
                </div>
            </section>
            <ContactForm/>
        </main>
    )
}

export default ContactUs;