import React from 'react'
import FirstImage from "../../../Assets/images/OurPartners/hero-image-01.png";
import SecondImage from "../../../Assets/images/OurPartners/hero-image-02.png";
import MwaliLogo from "../../../Assets/images/OurPartners/mwali-logo.png";
import ArrowUp from "../../../Assets/images/OurPartners/arrow-top.svg";
import HeroGrid from "../../Constant/HeroGrid/HeroGrid";
import ContactForm from "../../Constant/ContactForm/Contact Form";

function OurPartners () {
    return (
        <main className='main'>
            <HeroGrid
                secondBackgroundImage={SecondImage}
                firstBackgroundImage={FirstImage}
                title='Our partners'
                subtitle='For the past several years we have been cooperating with different companies and organisations'
            />
            
            <section className='page-banner-section'>
                <div className='page-banner-container container'>
                    <p className='page-banner-text'>
                        <span className='gray-text'>
                            One of the most known of our partners is                        
                        </span>
                        &nbsp;
                        Mwali International Services Authority (M.I.S.A.)
                        <span className='gray-text'>
                            , which specializes in providing different financial services in the jurisdiction of Mwali, Comoros.                       
                        </span>
                    </p>
                    <p className='page-banner-text small gray'>
                        Our company has created strong business relationships with that organisation and for several years we tightly cooperate together seeking to provide a qualified legal support to their clients.
                    </p>
                </div>
                
                <div className='page-banner-wrapper container'>
                    <div className='page-banner-border'></div>
                    <div className='page-banner-dark-container'>
                        <div className='page-banner-logo'>
                            <img src={MwaliLogo} alt='logo img' className='page-banner-logo-img'/>
                            <span className='text'>
                                Mwali International <br/>
                                Services Authority <br/>
                                (M.I.S.A.)
                            </span>
                        </div>
                        <img src={ArrowUp} alt='arrow up' className='page-banner-arrow logo' />
                    </div>
                </div>
                
            </section>

            <ContactForm/>
        </main>
    )
}

export default OurPartners;