import React from 'react';
import './Contact Form.css';
import { IoSend } from "react-icons/io5";

function ContactForm() {
    return (
        <div className='contact-section'>
            <div className='contact-container container'>
                <p className='contact-title'>
                    Feel free to contact us any time! We’ll get back to you as soon as we can.
                </p>
                <form className='contact-form'>
                    <div className='sub-container'>
                        <input
                            type='text'
                            className='contact-input'
                            placeholder='Name'
                            name='name'
                            required
                        />
                        <input
                            type='email'
                            className='contact-input'
                            placeholder='Email'
                            name='email'
                            required
                        />
                    </div>
                    <input
                        type='text'
                        className='contact-input'
                        placeholder='Subject'
                        name='subject'
                        required
                    />
                    <textarea
                        className='contact-input contact-textarea'
                        placeholder='Message'
                        rows='12'
                        cols='40'
                    />
                    <button
                        type='submit'
                        className='contact-submit-button button'
                    >
                        Contact Us
                    </button>
                </form>
            </div>

        </div>
    );
}

export default ContactForm;
