import {useState} from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import ContactForm from "../../Constant/ContactForm/Contact Form";

function NawabAli () {
    
    return(
        <main className='main'>
            <div className='portfolio-header'>
                <div className='portfolio-header-container container'>
                    <h1 className='portfolio-header-title'>
                        Nawab Ali
                    </h1>

                    <p className='portfolio-header-description'>
                        Business Profile: Nawab Ali, Esq.
                        <br/> <br/>
                        Master of International Business Law, University of Perpignan
                    </p>
                </div>
            </div>
            
            <section className='portfolio-section section'>
                <div className='portfolio-container container'>
                    <div className='portfolio-content'>
                        <h1 className='portfolio-title'>
                            Introduction:
                        </h1>
                        <p className='portfolio-description'>
                            Nawab Ali is a highly seasoned and accomplished legal professional with a distinguished
                            career spanning two decades. Holding a Master's degree in International Business Law from
                            the prestigious University of Perpignan, Nawab has consistently demonstrated expertise,
                            diligence, and a commitment to excellence in the field of law.
                        </p>

                        <h1 className='portfolio-title'>
                            Education:
                        </h1>
                        <p className='portfolio-description'>
                            Nawab Ali earned his Master of International Business Law degree from the University of
                            Perpignan, equipping him with a comprehensive understanding of the intricacies of
                            international legal frameworks. His academic background reflects a dedication to staying
                            abreast of the latest developments in the legal landscape.
                        </p>
                        <h1 className='portfolio-title'>
                            Professional Experience:
                        </h1>
                        <p className='portfolio-description'>
                            For the past two decades, Nawab Ali has been an integral part of the esteemed law firm,
                            Cabinet d'Avocats Mohamed & C. His tenure as a notary has been marked by a relentless
                            pursuit of legal excellence, offering a broad spectrum of legal services to clients with a
                            focus on precision, ethics, and client satisfaction.
                        </p>
                        <h1 className='portfolio-title'>
                            Specialization:
                        </h1>
                        <p className='portfolio-description'>
                            Nawab Ali specializes in notarial services, bringing a wealth of experience and a deep
                            understanding of international business law to every case. His proficiency extends to
                            navigating the complexities of legal transactions, ensuring compliance with local and
                            international regulations.
                        </p>
                        <h1 className='portfolio-title'>
                            Comoros Bar Affiliation:
                        </h1>
                        <p className='portfolio-description'>
                            Nawab Ali is a distinguished member of the Comoros Bar, contributing to the legal landscape
                            of the Comoros Islands with his expertise and commitment to upholding the highest standards
                            of professional conduct. As a seasoned legal practitioner, he is well-versed in the nuances
                            of the legal system in Comoros and adept at providing strategic legal counsel.
                        </p>
                        <h1 className='portfolio-title'>
                            Client-Centric Approach:
                        </h1>
                        <p className='portfolio-description'>
                            Nawab Ali is known for his client-centric approach, tailoring legal solutions to meet the
                            unique needs of each client. His commitment to transparency, integrity, and effective
                            communication underscores his reputation as a trusted legal advisor.
                        </p>
                        <h1 className='portfolio-title'>
                            Languages:
                        </h1>
                        <p className='portfolio-description'>
                            Fluent in multiple languages, Nawab Ali ensures effective communication with a diverse
                            clientele, fostering a collaborative and inclusive legal environment.
                        </p>

                        <p className='portfolio-description'>
                            Nawab Ali stands as a paragon of legal expertise, offering invaluable contributions to the
                            legal profession in the Comoros Islands. His unwavering dedication to the pursuit of justice
                            and legal excellence continues to shape the legal landscape in the region.
                        </p>
                    </div>

                    <div className="table-container">
                        <table className="table">
                            <thead className="table-header">
                            <tr>
                                <th className="table-heading">№</th>
                                <th className="table-heading">document number</th>
                                <th className="table-heading">Date of Issue</th>
                                <th className="table-heading">document type</th>
                                <th className="table-heading">status</th>
                            </tr>
                            </thead>
                            <tbody className="table-body">
                            <tr className="table-row">
                                <td className="table-cell">1</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">2</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">2</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">3</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">4</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">5</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='portfolio-list-container'>
                        <h1 className='portfolio-list-title'>
                            How to verify the authenticity of a document:
                        </h1>
                        <ul className='portfolio-list'>
                            <li className='portfolio-list-item'>
                                1. Scan the QR code located on the document.
                            </li>
                            <li className='portfolio-list-item'>
                                2. Enter the apostille registration number in the verification field on the website (link to our site).                             </li>
                            <li className='portfolio-list-item'>
                                3. Ensure that the information in the registry matches what is listed in the table.
                            </li>
                        </ul>

                    </div>
                    
                    <h1 className='section-title'>
                        Frequently Asked Questions
                    </h1>
                </div>
                <FAQ/>
                <ContactForm />
            </section>
        </main>
    )
}

export default NawabAli;



const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const faqs = [
        { question: "What should I do if a document is missing from the table?", answer: "Please contact our office to clarify the information by sending an email to support@cabadvocats.com. The document might still be in the process of registration." },
        { question: "How can I find out if my document is ready?", answer: "Check the document's status using its registration number or contact us using the provided details: support@cabadvocats.com." },
    ];

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="faq-container container">
            {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                    <button className="faq-question" onClick={() => toggleFAQ(index)}>
                        {faq.question}
                        <span className="faq-icon">{openIndex === index ?
                            <IoIosArrowDown />
                            : <IoIosArrowUp />
                        }</span>
                    </button>
                    {openIndex === index && <p className="faq-answer">{faq.answer}</p>}
                </div>
            ))}
        </div>
    );
};


