import React, {useState} from "react";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import ContactForm from "../../Constant/ContactForm/Contact Form";
import SectionImageOne from '../../../Assets/images/MohamedBoina/section-image-01.png'
import SectionImageTwo from '../../../Assets/images/MohamedBoina/section-image-02.png'

function MohamedBoina() {

    return (
        <main className='main'>
            <div className='portfolio-header'>
                <div className='portfolio-header-container container'>
                    <h1 className='portfolio-header-title'>
                        Thilivhali Okello
                    </h1>

                    <p className='portfolio-header-description'>
                        Business Profile: Thilivhali Okello
                        <br/> <br/>
                        Business Tax Lawyer
                    </p>
                </div>
            </div>

            <section className='layout-section section'>
                <div className='layout-container container'>
                    <div
                        className='alternating-layout-item'
                    >
                        {/* Left Content */}
                        <div className='alternating-layout-left'>
                            <h2 className='alternating-title'>About Us:</h2>
                            <p className='alternating-text'>
                                Founded by MOHAMED BOINA , a distinguished legal professional
                                with a solid academic background from the University of Comoros in the field of business
                                Law, our firm boasts a decade of expertise as a reputable notary. Committed to upholding
                                the
                                highest standards of legal practice, CABINET D'AVOCATS MOHAMED & C Law Law Firm is your
                                go-to destination for comprehensive legal solutions tailored to the unique needs of
                                businesses in the Comoros.
                            </p>
                        </div>

                        {/* Right Content */}
                        <div className='alternating-layout-right'>
                            <img src={SectionImageOne} alt='image' className='alternating-layout-img'/>
                        </div>
                    </div>

                    <div className='alternating-layout-middle'>
                        <h2 className='alternating-title'>About Us:</h2>
                        <p className='alternating-text'>
                            Our core strength lies in business tax law, where CABINET D'AVOCATS MOHAMED & C leverages
                            academic knowledge and practical experience to offer strategic advice and meticulous
                            guidance. With a deep understanding of the local legal landscape, we navigate the
                            intricacies of Comoros tax regulations to ensure your business remains compliant and
                            optimized for success.
                        </p>
                    </div>

                    <div
                        className='alternating-layout-item'
                    >
                        {/* Right Content */}
                        <div className='alternating-layout-right'>
                            <img src={SectionImageTwo} alt='image' className='alternating-layout-img'/>
                        </div>
                        
                        {/* Left Content */}
                        <div className='alternating-layout-left'>
                            <h2 className='alternating-title'>Services:</h2>
                            <h3 className='alternating-subtitle'>Business Tax Consultation:</h3>
                            <p className='alternating-text'>
                                Navigate the complexities of business tax law with confidence. We provide tailored
                                consultations to optimize your tax structure, enhance compliance, and minimize
                                liabilities.
                            </p>
                            <h3 className='alternating-subtitle'>
                                Notarial Services:
                            </h3>
                            <p className='alternating-text'>
                                Benefit from a decade of experience as a notary. Our firm facilitates secure and legally binding transactions, ensuring the protection of your interests.
                            </p>
                            <h3 className='alternating-subtitle'>
                                Legal Counsel:
                            </h3>
                            <p className='alternating-text'>
                                CABINET D'AVOCATS MOHAMED & C Law Firm serves as your dedicated legal counsel, offering
                                insights into business law, contracts, and regulatory compliance. Our proactive approach
                                helps mitigate risks and foster a secure business environment.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className='portfolio-section section'>
                <div className='portfolio-container container'>
                    <div className='portfolio-content'>
                        <h1 className='portfolio-title'>
                            Commitment to Excellence:
                        </h1>
                        <p className='portfolio-description'>
                            At CABINET D'AVOCATS MOHAMED & C Law Firm, we are driven by a commitment to excellence, professionalism, and client satisfaction. We take pride in our local roots, understanding the unique challenges and opportunities that businesses encounter in the Comoros Islands. Our client-centric approach ensures personalized and effective legal solutions.
                        </p>

                        <h1 className='portfolio-title'>
                            Contact Us:
                        </h1>
                        <p className='portfolio-description'>
                            Discover the difference that dedicated legal expertise can make for your business. Contact our Law Firm for comprehensive legal services that elevate your business to new heights.
                        </p>
                    </div>

                    <div className="table-container">
                        <table className="table">
                            <thead className="table-header">
                            <tr>
                                <th className="table-heading">№</th>
                                <th className="table-heading">document number</th>
                                <th className="table-heading">Date of Issue</th>
                                <th className="table-heading">document type</th>
                                <th className="table-heading">status</th>
                            </tr>
                            </thead>
                            <tbody className="table-body">
                            <tr className="table-row">
                                <td className="table-cell">1</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">2</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">2</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">3</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">4</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">5</td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                                <td className="table-cell"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='portfolio-list-container'>
                        <h1 className='portfolio-list-title'>
                            How to verify the authenticity of a document:
                        </h1>
                        <ul className='portfolio-list'>
                            <li className='portfolio-list-item'>
                                1. Scan the QR code located on the document.
                            </li>
                            <li className='portfolio-list-item'>
                                2. Enter the apostille registration number in the verification field on the website
                                (link to our site).
                            </li>
                            <li className='portfolio-list-item'>
                                3. Ensure that the information in the registry matches what is listed in the table.
                            </li>
                        </ul>

                    </div>

                    <h1 className='section-title'>
                        Frequently Asked Questions
                    </h1>
                </div>
                <FAQ/> 
                <ContactForm/>
            </section>
        </main>
    )
}

export default MohamedBoina;


const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const faqs = [
        {
            question: "What should I do if a document is missing from the table?",
            answer: "Please contact our office to clarify the information by sending an email to support@cabadvocats.com. The document might still be in the process of registration."
        },
        {
            question: "How can I find out if my document is ready?",
            answer: "Check the document's status using its registration number or contact us using the provided details: support@cabadvocats.com."
        },
    ];

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="faq-container container">
            {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                    <button className="faq-question" onClick={() => toggleFAQ(index)}>
                        {faq.question}
                        <span className="faq-icon">{openIndex === index ?
                            <IoIosArrowDown/>
                            : <IoIosArrowUp/>
                        }</span>
                    </button>
                    {openIndex === index && <p className="faq-answer">{faq.answer}</p>}
                </div>
            ))}
        </div>
    );
};