import React from 'react';
import './Home.css'
import HeroSection from "../../Constant/HeroSection/HeroSection";
import HeroBg from '../../../Assets/images/Home/hero-bg.png'
import HomeInfoImg from '../../../Assets/images/Home/home-info-image.png'
import HomeLinkImage from '../../../Assets/images/Home/home-link-image.png'
import {Link} from "react-router-dom";
import ContactForm from "../../Constant/ContactForm/Contact Form";

const Home = () => {
    return (
       <main className='main'>
           <HeroSection
               heroBackground={HeroBg}
               alt="A background"
               title="Your Trusted Partner in Legal Matters"
               buttonTitle="Contact Us"
               buttonLink="/contact-us"
           />

           <section className='home-info-section'>
               <div className='home-info-container container'>
                   <div className='home-info-image'>
                       <img src={HomeInfoImg} alt='homme info image' className='home-info-img'/>
                   </div>

                   <div className='home-info-content'>
                       <h1 className='home-info-title'>
                           CABINET D'AVOCATS MOHAMED & C
                           is a law firm located in Fomboni, Mwali, Comoros.
                       </h1>
                       <p className='home-info-description'>
                           Founded in 2003 by MOHAMED BOINA, a distinguished legal professional with
                           a solid academic background from
                           the University of Comoros in the field
                           of business Law, our firm boasts a decade of expertise as a reputable notary. Committed to
                           upholding the highest standards of legal practice, CABINET D'AVOCATS MOHAMED & C Law
                           Firm is your go-to destination for comprehensive legal solutions
                           tailored to the unique
                           needs of businesses
                           in Comoros.
                       </p>
                   </div>
               </div>
           </section>
           
           <section className='home-link-section'>
               <div className='home-link-container'>
                   <ul className='home-link-list container'>
                       <li className='home-link-item'>
                           <Link to='' className='home-link-link'>
                               Divorce proceedings
                           </Link>
                       </li>
                       <li className='home-link-item'>
                           <Link to='' className='home-link-link'>
                               Division of Property in Divorce
                           </Link>
                       </li>
                       <li className='home-link-item'>
                           <Link to='' className='home-link-link'>
                               Criminal cases
                           </Link>
                       </li>
                       <li className='home-link-item'>
                           <Link to='' className='home-link-link'>
                               Real Estate and Construction Law in Comoros
                           </Link>
                       </li>
                       <li className='home-link-item'>
                           <Link to='' className='home-link-link'>
                               Real Estate
                           </Link>
                       </li>
                       <li className='home-link-item'>
                           <Link to='' className='home-link-link'>
                               Notary services
                           </Link>
                       </li>
                   </ul>
                   
                   <div className='home-link-image'>
                       <img src={HomeLinkImage} alt='home link image' className='home-link-img'/>
                   </div>
               </div>
           </section>
           
           <ContactForm/>
           
       </main>
    );
};

export default Home;
