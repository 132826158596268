import React from 'react'
import HeroGrid from "../../../Constant/HeroGrid/HeroGrid";
import FirstImage from "../../../../Assets/images/WhatWeDo/RealEstateConstruction/hero-image-01.png";
import SecondImage from "../../../../Assets/images/WhatWeDo/RealEstateConstruction/hero-image-02.png";
import ContactForm from "../../../Constant/ContactForm/Contact Form";


function RealEstateConstruction () {
    
    return (
        <main className='main'>
            <HeroGrid
                secondBackgroundImage={SecondImage}
                firstBackgroundImage={FirstImage}
                title="Real Estate and Construction Law in Comoros"
                subtitle='Real estate law is a very comprehensive branch of law with many sub-branches.'
                description='At the same time, since it is one of the branches of law with the highest number of exceptions, special expertise is required for disputes in the field of real estate law. '
            />

            <section className='text-banner-section'>
                <div className='text-banner-container container'>
                    <p className='text-banner-paragraph'>
                        <span className='gray-text'>
                            In these cases, which require special expertise and are difficult types of cases, 
                        </span>
                        &nbsp;
                        CABINET D'AVOCATS MOHAMED & C provides support in the entire legal process as Comoros Real Estate and
                        Construction Law.
                    </p>
                </div>
            </section>
            
            <ContactForm/>

        </main>
    )
}

export default RealEstateConstruction