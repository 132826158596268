import React from 'react'
import HeroGrid from "../../../Constant/HeroGrid/HeroGrid";
import FirstImage from "../../../../Assets/images/WhatWeDo/RealEstates/hero-image-01.png";
import SecondImage from "../../../../Assets/images/WhatWeDo/RealEstates/hero-image-02.png";
import ContactForm from "../../../Constant/ContactForm/Contact Form";
import sectionImageOne from "../../../../Assets/images/WhatWeDo/RealEstates/section-image-01.png";
import AlternatingSection from "../../../Constant/AlternatingSection/AlternatingSection";

const sectionsData = [
    {
        text:
        "However, this branch of law is considered to be Portfolio.css very difficult branch of law. Because sometimes even in Portfolio.css single case, there are issues that require expertise in different branches of law. For this reason, real estate law is Portfolio.css field that requires special expertise. In this area where exceptional situations are intense, it is not possible to solve cases without Portfolio.css lawyer who is an expert in his field.",
        image: sectionImageOne,
        alt: "Image 1",
    }
]

function RealEstate () {
    return (
        <main className='main'>
            <HeroGrid
                secondBackgroundImage={SecondImage}
                firstBackgroundImage={FirstImage}
                title='Real Estate'
                subtitle='Real Estate law is a branch of law that determines the rights on immovables such as houses, apartments, land, fields, as well as finding the solution of disputes in the same area. Real estate law is included in property law, which is a part of civil law.'
                description="If you are looking for Comoros Real Estate Law, Comoros Construction Law, or Comoros Real Estate & Construction Lawyer, the best answer is CABINET D'AVOCATS MOHAMED & C."
            />

            <section className='page-banner-section'>
                <div className='page-banner-container container'>
                    <p className='page-banner-text small gray'>
                        This branch of law is closely related to almost every segment of society.
                    </p>
                    <p className='page-banner-text'>
                        Issues such as title deed and registration cases, appropriation cases, expropriation of
                        immovable properties, including issues that seriously affect a large segment of the society,
                        such as the determination and registration of rental prices, are included in real estate law.
                        &nbsp;
                        <span className='gray-text'>
                            Litigation resolves disputes that may arise in these areas.                      
                        </span>
                    </p>
                </div>
            </section>

            <section className='section'>
                <AlternatingSection sections={sectionsData}/>
            </section>
            
            <ContactForm/>
        </main>
    )
}

export default RealEstate;