import React from 'react';
import './NotariesSociety.css';
import {useNavigate} from 'react-router-dom';
import HeroGrid from "../../Constant/HeroGrid/HeroGrid";
import HeroFirstImage from "../../../Assets/images/NotariesSociety/first-hero-img.png"
import HeroSecondImage from "../../../Assets/images/NotariesSociety/second-hero-img.png"
import ArrowRight from "../../../Assets/images/NotariesSociety/arrow-right.svg";

function NotariesSociety () {
    const navigate = useNavigate();
    
    return (
        <main className='main'>
            <HeroGrid 
                secondBackgroundImage={HeroSecondImage} 
                firstBackgroundImage={HeroFirstImage}
                title="The Notaries Society"
                description="Welcome to our company's notarial services page! Here you can find information about notaries who work with certified documents, as well as check the status and details of each issued apostille."
            />

            <section className='society-section'>

                <div className='society-container container'>
                    <div className='society-content'>
                        <h4 className='society-content-paragraph'>
                            Each notary registered in our system has their own personal page with a table of all
                            documents
                            that
                            have received an apostille. For your convenience, all data is presented in a clear and
                            accessible
                            table, including:
                        </h4>

                        <ul className='society-list'>
                            <li className='society-list-item'>
                                • Document number
                            </li>
                            <li className='society-list-item'>
                                • Apostille date
                            </li>
                            <li className='society-list-item'>
                                • Document number
                            </li>
                            <li className='society-list-item'>
                                • Document type
                            </li>
                            <li className='society-list-item'>
                                • Document status
                            </li>
                        </ul>
                    </div>

                    <div className='society-content'>
                        <h2 className='society-content-title'>
                            How it works:
                        </h2>

                        <ul className='society-list'>
                            <li className='society-list-item'>
                                1. Select a notary from the list.
                            </li>
                            <li className='society-list-item'>
                                2. Review the table of all documents registered by that notary.
                            </li>
                            <li className='society-list-item'>
                                3. To verify the authenticity of a document, you can use the apostille number and date
                                to
                                search on the official website or via the QR code provided on the document.
                            </li>
                        </ul>
                    </div>

                    <div className='society-content'>
                        <h1 className='society-content-title'>
                            Document verification:
                        </h1>

                        <p className='society-content-paragraph'>
                            All documents that have undergone the procedure can be verified in the electronic system. To do this, use the QR code placed on the document or enter the unique apostille number in the corresponding field on the website.
                        </p>
                    </div>
                </div>
                
                <div className='society-title society-section container'>
                    <h1 className='section-title'>
                        Our approved notaries:
                    </h1>
                </div>

                <div className='layout-row-container container'>

                    <article className='layout-row-article pointer' 
                             onClick={()=> navigate('navwab-ali')}
                    >
                        <div className='layout-row-article-content' >
                            <div >
                                <h2 className='layout-row-title'>
                                    Nawab Ali
                                </h2>
                                <p className='layout-row-title'>
                                    Master of International Business Law, University of Perpignan
                                </p>
                            </div>

                            <img src={ArrowRight} alt='arrow left' className='layout-row-arrow pointer'/>
                        </div>
                    </article>

                    <article className='layout-row-article pointer' onClick={()=> navigate('thilivhali-okello')}>
                        <div className='layout-row-article-content'>
                            <div>
                                <h2 className='layout-row-title'>
                                    Thilivhali Okello
                                </h2>
                                <p className='layout-row-title'>
                                    Business Tax Lawyer
                                </p>
                            </div>
                            <img src={ArrowRight} alt='arrow left' className='layout-row-arrow pointer'/>
                        </div>

                    </article>

                    <article className='layout-row-article pointer' onClick={()=> navigate('mohamed-boina')}>
                        <div className='layout-row-article-content'>
                            <div>
                                <h2 className='layout-row-title'>
                                    Mohamed Boina
                                </h2>
                                <p className='layout-row-title'>
                                    Attorney at Law - COMOROS BAR
                                </p>
                            </div>

                            <img src={ArrowRight} alt='arrow left' className='layout-row-arrow pointer'/>
                        </div>
                    </article>
                </div>

            </section>

        </main>
    )
}

export default NotariesSociety;