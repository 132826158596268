import React from "react";
import "./AlternatingSections.css";

const AlternatingSections = ({ sections }) => {
    return (
        <div className="alternating-sections">
            <div className="alternating-sections-container container">
                {sections.map((section, index) => (
                    <div
                        key={index}
                        className={`alternating-sections-item ${
                            index % 2 === 0 ? "grid-normal" : "grid-reverse"
                        }`}
                    >
                        <div className="alternating-sections-text">
                            {section.title && <h2 className="section-heading">{section.title}</h2>}
                            {section.text && <p className="section-text">{section.text}</p>}
                        </div>
                        <div className="alternating-sections-image">
                            <img
                                src={section.image}
                                alt={section.alt || "Section Image"}
                                className="section-image"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AlternatingSections;
