import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./components/Pages/Home/Home";
import DivorceProceedings from "./components/Pages/WhatWeDo/DivorceProceedings/DivorceProceedings";
import DivisionPropertyDivorce from "./components/Pages/WhatWeDo/DivisionPropertyDivorce/DivisionPropertyDivorce";
import RealEstateConstruction from "./components/Pages/WhatWeDo/RealEstateConstruction/RealEstateConstruction";
import RealEstate from "./components/Pages/WhatWeDo/RealEstates/RealEstate";
import CriminalCases from "./components/Pages/WhatWeDo/CriminalCase/CriminalCases";
import NotaryServices from "./components/Pages/WhatWeDo/NotaryServices/NotaryServices";
import NotariesServices from "./components/Pages/NotariesServices/Notaries&Services";
import NotariesSociety from "./components/Pages/NotariesSociety/NotariesSociety";
import NawabAli from "./components/Pages/NawabAli/NawabAli";
import ThilivhaliOkello from "./components/Pages/ThilivhaliOkello/ThilivhaliOkello";
import MohamedBoina from "./components/Pages/MohamedBoina/MohamedBoina";
import OurPartners from "./components/Pages/OurPartners/OurPartners";
import ContactUs from "./components/Pages/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./Utils/ScrollToTop";


function App() {
    return (
        <BrowserRouter>
            <Main />
        </BrowserRouter>
    );
}

function Main() {

    return (
        <>
            <Header/>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={
                    <Home/>
                } />
                <Route path="/divorce-proceedings" element={
                    <DivorceProceedings/>
                }/>
                <Route path="/division-of-property" element={
                    <DivisionPropertyDivorce />
                }/>
                <Route path="/real-estate-comoros" element={
                    <RealEstateConstruction />
                }/>
                <Route path="/real-estate" element={
                    <RealEstate />
                }/>
                <Route path="/criminal-cases" element={
                    <CriminalCases/>
                }/>
                <Route path="/notary-services" element={
                    <NotaryServices/>
                }/>
                <Route path="/notaries-services" element={
                    <NotariesServices/>
                }/>
                <Route path="/notaries-society" element={
                    <NotariesSociety />
                }/>
                <Route path="/notaries-society/navwab-ali" element={
                    <NawabAli />
                }/> 
                <Route path="/notaries-society/thilivhali-okello" element={
                    <ThilivhaliOkello />
                }/>
                <Route path="/notaries-society/mohamed-boina" element={
                    <MohamedBoina />
                }/>
                <Route path="/our-partners" element={
                    <OurPartners/>
                }/>
                <Route path="/contact-us" element={
                    <ContactUs/>
                }/>
            </Routes>
           <Footer/>
        </>
    );
}

export default App;