import React from "react";
import FirstImage from "../../../../Assets/images/WhatWeDo/DivisionPropertyDivorce/first-image.png";
import SecondImage from "../../../../Assets/images/WhatWeDo/DivisionPropertyDivorce/second-image.png";
import HeroGrid from "../../../Constant/HeroGrid/HeroGrid";
import AlternatingSection from "../../../Constant/AlternatingSection/AlternatingSection";
import sectionImageOne from "../../../../Assets/images/WhatWeDo/DivisionPropertyDivorce/section-image-01.png";
import sectionImageTwo from "../../../../Assets/images/WhatWeDo/DivisionPropertyDivorce/section-image-02.png";
import ContactForm from "../../../Constant/ContactForm/Contact Form";

const sectionsData = [
    {
        text: "Every case has Portfolio.css very important preparation process. As CABINET D'AVOCATS MOHAMED & C, we make property division, alimony, custody, and compensation requests within the framework of the demands of the clients in divorce cases, manage the process, and defend against these claims against the client.",
        image: sectionImageOne,
        alt: "Image 1",
    },
    {
        text: "On the other hand, with our experience and knowledge in the field of Family Law, we represent our clients who have decided to divorce in the best way in the divorce case filed.",
        image: sectionImageTwo,
        alt: "Image 2",
    }
];

const DivisionPropertyDivorce = () => {
    return (
        <main className="main">
            <HeroGrid
                title='Division of Property in Divorce'
                subtitle='Article 26 of the Family Code regulates the division of property.'
                description='Accordingly, in the matter of property division, there are two divisions of property: property acquired within the marriage union and personal property. '
                firstBackgroundImage={FirstImage}
                secondBackgroundImage={SecondImage}
            />

            <section className='text-banner-section'>
                <div className='text-banner-container container'>
                    <p className='text-banner-paragraph'>
                        While the movable and immovable properties acquired by the spouses during the period of the
                        marriage union are subject to sharing, the properties defined by the law as personal property
                        are not subject to sharing.
                    </p>
                    <br/>
                    <span className='gray-text text-banner-small-paragraph'>
                            The division of goods is made in such a way that at least 1/3 and at most 2/3 of the goods remain for one of the parties.
                    </span>
                </div>
            </section>

            <div className='section' style={{backgroundColor: '#f9fafb'}}>
                <AlternatingSection sections={sectionsData}/>
            </div>
            
            <ContactForm/>

        </main>
);
};

export default DivisionPropertyDivorce;
