import React from "react";
import "./AlternatingContainer.css";

const AlternatingContent = ({ sections, sectionTitle  }) => {
    return (
        <div className="alternating-section section">
            <div className="alternating-container container">

                <h1 className='section-title'>
                    {sectionTitle || 'Default Title'} {/* Default fallback */}
                </h1>

                {sections.map((section, index) => (
                    <div
                        key={index}
                        className={`alternating-container-item ${
                            index % 2 === 0 ? "default-layout" : "reverse-layout"
                        }`}
                    >
                        {/* Left Content */}
                        <div className={`alternating-content-left section-${index}`}>
                            {section.leftTitle && (
                                <h2 className={`alternating-title title-${index}`}>{section.leftTitle}</h2>
                            )}
                            {section.leftText && (
                                <p className={`alternating-text text-${index}`}>{section.leftText}</p>
                            )}
                        </div>

                        {/* Right Content */}
                        <div className={`alternating-content-right section-${index}`}>
                            {section.rightTitle  && (
                                <h2 className={`alternating-title title-${index}`}>{section.rightTitle}</h2>
                            )}
                            {section.rightText  && (
                                <p className={`alternating-text text-${index}`}>{section.rightText }</p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AlternatingContent;
